/* eslint-disable camelcase */

// 1
import { graphql } from "react-relay";
// @ts-ignore
import findOneMutationImpl from "mutations/findOneMutationImpl";
import {EntityContractorUser} from "mutations/types.d.ts";

// 2
const query = graphql`
  query checkByUsernameContractorUserQuery($username: String) {
    default_contractor_users_connection(where: {username: {_eq: $username}}) {
      edges {
        node {
          id
          auth_id
          contractor {
            id
            username
          }
        }
      }
    }
  }
`;
//firestore_id
// 3
export default async (input: string) =>
    input && findOneMutationImpl({checkLogin: false, query, variables: {username: input} });

(function () {
    if (
            // No Reflect, no classes, no need for shim because native custom elements
            // require ES2015 classes or Reflect.
            window.Reflect === undefined ||
            window.customElements === undefined ||
            // The webcomponentsjs custom elements polyfill doesn't require
            // ES2015-compatible construction (`super()` or `Reflect.construct`).
            window.customElements.polyfillWrapFlushCallback
            ) {
        return;
    }
    const BuiltInHTMLElement = HTMLElement;
    /**
     * With jscompiler's RECOMMENDED_FLAGS the function name will be optimized away.
     * However, if we declare the function as a property on an object literal, and
     * use quotes for the property name, then closure will leave that much intact,
     * which is enough for the JS VM to correctly set Function.prototype.name.
     */
    const wrapperForTheName = {
        'HTMLElement': /** @this {!Object} */ function HTMLElement() {
            return Reflect.construct(
                    BuiltInHTMLElement, [], /** @type {!Function} */ (this.constructor));
        }
    };
    window.HTMLElement = wrapperForTheName['HTMLElement'];
    HTMLElement.prototype = BuiltInHTMLElement.prototype;
    HTMLElement.prototype.constructor = HTMLElement;
    Object.setPrototypeOf(HTMLElement, BuiltInHTMLElement);
})();
/* eslint-disable camelcase */

// 1
import { graphql } from "react-relay";
// @ts-ignore
import {EntityEmployeeContract} from "mutations/types.d.ts";
import updateEmployeeDocImpl from "../Employee/updateEmployeeDocImpl";
import findByIdEmployeeContract from "./findByIdEmployeeContract";

// 2
const mutation = graphql`
  mutation updateEmployeeContractMutation($id: hr_employees_docs_contracts_pk_columns_input!, $_set: hr_employees_docs_contracts_set_input!) {
    update_hr_employees_docs_contracts_by_pk(pk_columns: $id, _set: $_set) {
        id
        created_at
        doc
        job_profile_doc
        expiry_updated
        removed
        employee{
            id
            fname
            lname
            phone
            email
            id_num
            passport_num
        }
        contract_details_list{
            id
            contract_type
            daily_working_hours
            end_of_trial_period
            end_time
            expiry_date
            notes
            start_date
            start_time
        }
        salary_info_list{
            id
            deductible_list{
                id
                created_at
                text
                value
            }
            payment_type
            salary
            salary_interval
            wage
        }
        company {
            id
            name
        }
        client {
            id
            name
        }
        project {
            id
            name
        }
        site {
            id
            name
        }
    }
  }
`;

// 3
export default async (attrs: EntityEmployeeContract) => updateEmployeeDocImpl({
    mutation,
    attrs,
    findByIdEmployeeFn: findByIdEmployeeContract,
    name: 'Contract',
    plural: 'contracts',
});

import AuditTrail from './AuditTrail/Main';
import Billing from './Billing/Billing';
import Chats from './Chats/Main';
import Profile from './Profile/Profile';
import Settings from './Settings/Settings';

import Support from './Support/Support';
import Users from './Users/Users';

const modules = {
    AuditTrail, 
    Billing, 
    Chats,
    Profile, 
    Settings, 
    Support, 
    Users
};
    
export {
    modules
};
var api = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/css-loader/dist/cjs.js!./PDFOrVideoUploadButton.lazy.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var refs = 0;
var dispose;
var options = {"injectType":"lazyStyleTag"};

options.insert = "head";
options.singleton = false;

var exported = {};

if (content.locals) {
  exported.locals = content.locals;
}

exported.use = function() {
  if (!(refs++)) {
    dispose = api(content, options);
  }

  return exported;
};

exported.unuse = function() {
  if (refs > 0 && !--refs) {
    dispose();
    dispose = null;
  }
};



module.exports = exported;
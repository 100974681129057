import React from "react";
import {observer} from 'mobx-react';
import {computed, observable} from 'mobx';

import {ClearableInput} from '../../components/inputs';

import ASelectInput from '../../components/inputs/ASelectInput';

@observer
class SelectInput extends ASelectInput {

    @observable
    state = {
        lists: {
            list: ['Electrical Tools', 'Electronics', 'Machinery', 'Fire Fighting Equipment', 'Furniture', 'Vehicles', 'Plant', 'Small Plant', 'Pneumatic Tools', 'Lifting Tools', 'Hydraulic Tools', 'Rigging Tools', 'Hand Tools', 'Office Equipment', 'Miscellaneous', 'Other Tools']
        },
        props: null
    };
    
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        let {className = ''} = this.props;
        className += ` SelectAssetTypeInput`;
        
        this.state.props = {
            ref: "input",
            key: new Date().getTime(),
            name: "asset_type",
            type: "select",
            placeholder: "Asset Type...",
            values: this.list,
            returnValue: true,
            add: true,
            ...this.props,
            defaultValue: this.selectedItem,
            className
        };
    }
    
    @computed
    get selectedItem() {
        const {defaultValue} = this.props;
        if(defaultValue) {
            if(defaultValue.label && defaultValue.value) {
                return defaultValue;
            }
            return {value: defaultValue, label: defaultValue};
        }
        return null;
    }
    
    render() {
        const {props} = this.state;
        if(!props) return null;

        return <ClearableInput {...props}/>;
    }
}

export default SelectInput;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../css/ANewTableFormDialog.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js!../../ANewEmployeeDocDialog.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../css/ANewDialog.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, "#dialog-overlay.Employees-new-dialog .dialog-container{\r\n    height: calc(100% - 35px);\r\n    width: 100%;\r\n    max-width: 1150px;\r\n}\r\n#dialog-overlay.Employees-new-dialog .row-tabs {\r\n    padding: 0 !important;\r\n}\r\n#dialog-overlay.Employees-new-dialog .controls.col-lg-12,\r\n#dialog-overlay.Employees-new-dialog .row.col-lg-12{\r\n    width: 100%;\r\n}\r\n#dialog-overlay.Employees-new-dialog .dialog-content .NewDialogSpecificComplianceRequirementsTab-tab.active .table,\r\n#dialog-overlay.Employees-new-dialog .dialog-content .NewDialogTeamMemberPermissionsTab-tab.active .table{\r\n    width: 100% !important;\r\n    margin: 0 !important;\r\n}\r\n#dialog-overlay.Employees-new-dialog .dialog-content .NewDialogSpecificComplianceRequirementsTab-tab.active h6{\r\n    color: red;\r\n    font-style: italic;\r\n}", ""]);
// Exports
module.exports = exports;

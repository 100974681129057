import  AdminUsers from './AdminUsers/Main';

import  Billing from './Billing/Main';
import  Support from './Support/Main';

import  Companies from './Companies/Main';
import  Clients from './Clients/Main';
import  Projects from './Projects/Main';
import  Sites from './Sites/Main';
import  Users from './Users/Main';
import  Modules from './Modules/Main';

import  ScheduledTasks from './ScheduledTasks/Main';

const modules = {
    AdminUsers,
	
    Billing,
    Support,
	
    Companies, 
    Clients, 
    Projects, 
    Sites,
    Users, 
    Modules,
    ScheduledTasks
};
    
export {
    modules
};